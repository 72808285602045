import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { cn } from '../../utils/tailwind'
import ProductList from '../ProductList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDown,
  faAngleUp,
  faArrowDownToLine,
  faMinus,
  faPlus,
  faXmark
} from '@fortawesome/sharp-regular-svg-icons'
import { convertGramToKilo, formatCurrency } from '../../utils/utils'
import { StoreState, useBoundStore } from '../../store'
import CartPdfLinks from './CartPdfLinks'
import ErrorMessage from '~/components/ErrorMessage'
import Modal from '~/components/Modal'

interface OpenTab {
  [reference: string]: number
}

interface Props {
  cart: Cart
  increaseQuantity: (uid: string, reference: string, quantity: number) => void
  decreaseQuantity: (uid: string, reference: string, quantity: number) => void
  removeItem: (uid: string, reference: string) => void
}

const CartListing = ({
  cart,
  increaseQuantity,
  decreaseQuantity,
  removeItem
}: Props) => {
  const { t } = useTranslation()
  const { user, canSeePrices } = useBoundStore((state: StoreState) => ({
    user: state.user,
    canSeePrices: state.user?.canSeePrices
  }))

  const [openTab, setOpenTab] = useState<OpenTab>({})
  const [showPdfLinks, setShowPdfLinks] = useState<{
    [reference: string]: boolean
  }>({})
  const [showUpdateMessage, setShowUpdateMessage] = useState(false)

  const switchTab = (reference: string, id: number) => {
    setOpenTab((prevState) => ({
      ...prevState,
      [reference]: prevState[reference] == id ? 0 : id
    }))
  }

  const togglePdfLinks = (reference: string) => {
    setShowPdfLinks((prevState) => ({
      ...prevState,
      [reference]: !prevState[reference]
    }))
  }

  return (
    <div className="mb-6">
      <ErrorMessage>
        <div>
          {t(
            'Projektkorgen kommer att försvinna i och med uppdatering tisdag 19/11 09:30. '
          )}
          <span
            className="cursor-pointer underline"
            onClick={() => setShowUpdateMessage(true)}
          >
            {t('Läs mer om vad det innebär')}
          </span>
        </div>
      </ErrorMessage>
      {showUpdateMessage ? (
        <Modal handleClose={() => setShowUpdateMessage(false)}>
          <h2 className="heading-l mb-6">
            Viktig information om projektkorgen
          </h2>
          <div className="mb-8">
            <p>
              I samband med en stor uppdatering av konfiguratorn tisdagen den 19
              november kl. 09:30 kommer projektkorgen att försvinna. Istället
              kommer du nu endast ha en historik, eftersom du från och med
              uppdateringen har möjlighet att skapa flera takytor i en
              konfiguration och därmed får en sammanslagen artikelspecifikation
              genom att bara generera en rapport för ditt projekt.
              <br />
              <br />
              Om du har projekt i projektkorgen som kräver sammanslagna
              artikelspecifikationer, vänligen ladda ner och spara dessa innan
              uppdateringen lanseras, eftersom projektkorgen därefter inte
              längre kommer att vara tillgänglig.{' '}
              <span className="font-bold">
                Alla dina tidigare projekt finns fortfarande kvar i historiken,
                så ingen information kommer att gå förlorad.
              </span>
            </p>
          </div>
        </Modal>
      ) : null}
      <div className="mb-16 max-w-[526px] text-base">
        {t(
          'Nedan kan du se alla konfigurationer som lagts till i projektkorgen.'
        )}

        {user !== null
          ? t(
              ' Klicka på en konfiguration för att återuppta den och göra eventuella justeringar.'
            )
          : null}
      </div>
      <div>
        <div className="grid grid-cols-4 border-b border-grayscale-25 pb-4 text-lg font-bold">
          <div className="col-span-2">{t('Konfiguration')}</div>
          <div>{t('Kvantitet')}</div>
          {canSeePrices ? <div>{t('Totalsumma')}</div> : null}
        </div>
        {cart.configurations.map((item, key) => (
          <div
            key={key}
            className="grid grid-cols-4 gap-y-6 border-b border-grayscale-25 py-4 text-base"
          >
            <div className="col-span-2">
              <div className="mb-2 font-bold">
                {user ? (
                  <a
                    href={`#/parallel/${item.reference}`}
                    className="font-bold no-underline hover:underline"
                  >
                    {item.name}
                  </a>
                ) : (
                  <div>{item.name}</div>
                )}
              </div>
              <div>{`${t('Referensnummer')}: ${item.reference}`}</div>
            </div>

            <div className="flex items-center gap-4">
              <button
                onClick={() =>
                  decreaseQuantity(item.uid, item.reference, item.quantity)
                }
                className="flex h-6 w-6 items-center justify-center rounded-full bg-silver-75"
              >
                <FontAwesomeIcon
                  icon={faMinus}
                  size="lg"
                />
              </button>
              <div className="text-lg font-bold">{item.quantity}</div>
              <button
                onClick={() =>
                  increaseQuantity(item.uid, item.reference, item.quantity)
                }
                className="flex h-6 w-6 items-center justify-center rounded-full bg-silver-75"
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  size="lg"
                />
              </button>
            </div>
            <div>
              <div
                className={cn(
                  'flex h-fit items-center justify-between',
                  !canSeePrices && 'justify-end gap-4'
                )}
              >
                <div
                  className={cn(
                    item.summary.price !== item.summary.originalPrice &&
                      'text-red-100'
                  )}
                >
                  {formatCurrency(item.summary.price)}
                </div>
                <div>
                  <div
                    className={cn(
                      'relative flex w-fit cursor-pointer items-center gap-2 border border-black bg-white p-4 font-medium',
                      showPdfLinks[item.reference] &&
                        'bg-grayscale-75 text-white'
                    )}
                    onClick={() => {
                      if (canSeePrices) {
                        togglePdfLinks(item.reference)
                      } else {
                        window.open(item.pdfUrl, '_blank')
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowDownToLine} />
                    {t('PDF')}
                    {showPdfLinks[item.reference] && user ? (
                      <CartPdfLinks pdfUrl={item.pdfUrl} />
                    ) : null}
                  </div>
                </div>
                <div
                  onClick={() => removeItem(item.uid, item.reference)}
                  className="cursor-pointer text-lg"
                >
                  <FontAwesomeIcon icon={faXmark} />
                </div>
              </div>
            </div>

            <div
              className={cn(
                'flex cursor-pointer items-center gap-2',
                openTab[item.reference] === 1 && 'font-bold'
              )}
              onClick={() => switchTab(item.reference, 1)}
            >
              {t('Artikelspecifikation')}
              <FontAwesomeIcon
                icon={openTab[item.reference] === 1 ? faAngleUp : faAngleDown}
              />
            </div>
            <div
              className={cn(
                'flex cursor-pointer items-center gap-2',
                openTab[item.reference] === 2 && 'font-bold'
              )}
              onClick={() => switchTab(item.reference, 2)}
            >
              {t('Mer information')}
              <FontAwesomeIcon
                icon={openTab[item.reference] === 2 ? faAngleUp : faAngleDown}
              />
            </div>

            {openTab[item.reference] === 1 ? (
              <div className="col-span-full mb-6">
                <ProductList
                  products={item.products}
                  variant="cart"
                />
              </div>
            ) : null}

            {openTab[item.reference] === 2 ? (
              <div className="col-span-2 col-start-2 mb-6 flex flex-col gap-2">
                <div className="grid grid-cols-2">
                  <div className="font-bold">{t('Datum')}</div>
                  <div>{item.summary.projectDetails.date}</div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="font-bold">{t('Antal paneler')}</div>
                  <div>{`${item.summary.numberOfPanels} ${t('st')}`}</div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="font-bold">{t('Total vikt infästning')}</div>
                  <div>{`${convertGramToKilo(
                    item.summary.totalMountingWeight
                  )} ${t('kg')}`}</div>
                </div>
                <div className="grid grid-cols-2">
                  <div className="font-bold">{t('Total vikt paneler')}</div>
                  <div>{`${convertGramToKilo(
                    item.summary.totalPanelWeight
                  )} ${t('kg')}`}</div>
                </div>
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  )
}

export default CartListing
