import { InputHTMLAttributes } from 'react'
import { FieldValues, RegisterOptions, useFormContext } from 'react-hook-form'
import { cn } from '~/utils/tailwind'
import FieldErrorMessage from '../FieldErrorMessage'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: keyof FieldValues
  options: formOptionsItemType[]
  label?: string
  className?: string
  rules?: RegisterOptions<FieldValues>
}

const BasicRadio = ({
  name,
  options,
  rules,
  label,
  className,
  disabled
}: Props) => {
  const {
    register,
    formState: { errors }
  } = useFormContext()
  return (
    <div className='col-span-full'>
      {label !== undefined ? <div className={cn('mb-2 flex w-full text-sm font-bold uppercase items-center')}>{label}</div> : null}
      <div
        className={cn(
          'flex flex-col text-base font-light',
          className
        )}
      >
        {options.map((option, index) => (
          <div
            key={index}
            className='mb-2 focus-within:border'
          >
            <label
              className="flex"
              htmlFor={`${option.label}_${option.value}`}
            >
              <input
                type="radio"
                id={`${option.label}_${option.value}`}
                {...register(name, rules)}
                disabled={disabled || option?.rules?.disabled}
                value={option.value}
                className="peer absolute h-0 w-0"
              />
              <div
                className={cn(
                  'border-1 mr-2 flex h-4 w-4 items-center rounded-full border border-black peer-checked:hidden'
                )}
              ></div>
              <div className='border-1 mr-2 hidden h-4 w-4 items-center justify-center rounded-full border border-black peer-checked:flex'>
                <div className='h-2 w-2 rounded-full bg-primary'></div>
              </div>
              {option.label}
            </label>
          </div>
        ))}
        <>
          <FieldErrorMessage
            name={name}
            errors={errors}
          />
        </>
      </div>
    </div>
  )
}

export default BasicRadio
