import { shallow } from 'zustand/shallow'
import { StoreState, useBoundStore } from '../store'
import { getSectionLabelText } from '../utils/configurator'
import { cn } from '../utils/tailwind'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/sharp-regular-svg-icons'
import Button from './buttons/Button'
import DebugModal from './DebugModal'
import { useState } from 'react'

const UtilizationRates = () => {
  const { t } = useTranslation()
  const { panelAreas, panelAreaSections, system } = useBoundStore(
    (state: StoreState) => ({
      panelAreaSections: state.panelAreaSections,
      panelAreas: state.panelAreas,
      system: state.conditions.configurationSystem
    }),
    shallow
  )

  const [openDebugModal, setOpenDebugModal] = useState<number | null>(null)

  const results = (panelAreaSection: PanelAreaSection) => {
    const panelAreaIndex = panelAreas.findIndex(
      (panelArea) => panelArea.uid === panelAreaSection.panelAreaUid
    )
    const panelArea = panelAreas[panelAreaIndex]
    const values = panelAreaSection.result.values as ResultValuesParallell
    return (
      <>
        <h3 className="mb-1 text-[20px] leading-[23px]">
          {t('Kapacitet i infästningsskena')}
        </h3>
        <p
          className={cn(
            'mb-5 py-1',
            values.hi.railConsolePartMax > 100 && 'bg-red-25 text-red-100'
          )}
        >{`${t('Utstickande skena:')} ${
          Math.round(values.hi.railConsolePartMax * 10) / 10
        }%`}</p>
        {resultList(
          values,
          panelAreaSection.inEdgeZone,
          panelAreaSection.result.supportPlatesAllowed &&
            panelArea.useSupportPlates
        )}
      </>
    )
  }

  const resultsLow = (panelAreaSection: PanelAreaSection) => {
    return (
      <>
        <h3 className="mb-6 text-[20px] leading-[23px]">
          {t('Kapacitet/infästning till tak resp. skena')}
        </h3>
        {resultListSystemLow(
          panelAreaSection.result.values as ResultValuesLow,
          panelAreaSection.inEdgeZone
        )}
      </>
    )
  }

  const getResultTableRow = (
    result: number,
    edgeZoneResult: number,
    limit: number,
    edgeZoneLimit: number,
    resultTitle: string,
    isInEdgeZone: boolean
  ) => {
    return (
      <div
        className={cn(
          'table-row',
          (result > limit ||
            (edgeZoneResult > edgeZoneLimit && isInEdgeZone)) &&
            'bg-red-25 text-red-100'
        )}
      >
        <div className="table-cell py-1 font-bold">{resultTitle}</div>
        <div
          className={cn(
            'table-cell py-1',
            edgeZoneResult > edgeZoneLimit && 'bg-red-25 text-red-100'
          )}
        >
          {`${Math.round(edgeZoneResult * 10) / 10}% ${
            edgeZoneResult > edgeZoneLimit
              ? `(${t('max')} ${edgeZoneLimit}%)`
              : ''
          }`}
        </div>
        <div
          className={cn(
            'table-cell py-1',
            result > limit && 'bg-red-25 text-red-100'
          )}
        >
          {`${Math.round(result * 10) / 10}% ${
            result > limit ? `(${t('max')} ${limit}%)` : ''
          }`}
        </div>
      </div>
    )
  }

  const resultList = (
    resultsData: ResultValuesParallell,
    isInEdgeZone: boolean,
    useSupportPlates: boolean
  ) => {
    const resultsKeys = [
      'maxLift',
      'maxPressure',
      'railMountMaxLift',
      'railMountMaxPressure',
      'railMountMaxHorizontal',
      'railMountMaxLiftAndHorizontal',
      'railMountMaxPressureAndHorizontal',
      'railConsolePartMax'
    ]

    const resultsKeysWithSupportPlates = [
      'maxLiftWithSupportPlates',
      'maxPressure',
      'railMountMaxLiftWithSupportPlates',
      'railMountMaxPressure',
      'railMountMaxHorizontalWithSupportPlates',
      'railMountMaxLiftAndHorizontalWithSupportPlates',
      'railMountMaxPressureAndHorizontal',
      'railConsolePartMax'
    ]

    const keys = useSupportPlates ? resultsKeysWithSupportPlates : resultsKeys
    const edgeZoneKeys = resultsKeys
    const limit = resultsData.hi.limit
    const edgeZoneLimit = resultsData.fgj.limit
    const results = resultsData.hi
    const edgeZoneResults = resultsData.fgj

    return results !== undefined && results !== null ? (
      <div className="section">
        <div className="mb-8 table w-full table-fixed">
          <div className="table-row">
            <div className="table-cell py-1"></div>
            <div className="table-cell py-1 font-bold">
              {t('I randzon (F, G, J)')}
            </div>
            <div className="table-cell py-1 font-bold">
              {t('Innanför randzon (H, I)')}
            </div>
          </div>
          {getResultTableRow(
            results[keys[0] as keyof ResultValues] || 0,
            edgeZoneResults[edgeZoneKeys[0] as keyof ResultValues] || 0,
            limit,
            edgeZoneLimit,
            getResultsValueText(keys[0]),
            isInEdgeZone
          )}
          {getResultTableRow(
            results[keys[1] as keyof ResultValues] || 0,
            edgeZoneResults[edgeZoneKeys[1] as keyof ResultValues] || 0,
            limit,
            edgeZoneLimit,
            getResultsValueText(keys[1]),
            isInEdgeZone
          )}
        </div>
        <h3 className="mb-6 text-[20px]">
          {t('Kapacitet för infästningar mellan skena och tak')}
        </h3>
        <div className="table w-full table-fixed">
          <div className="table-row">
            <div className="table-cell py-1"></div>
            <div className="table-cell py-1 font-bold">
              {t('I randzon (F, G, J)')}
            </div>
            <div className="table-cell py-1 font-bold">
              {t('Innanför randzon (H, I)')}
            </div>
          </div>
          {getResultTableRow(
            results[keys[2] as keyof ResultValues] || 0,
            edgeZoneResults[edgeZoneKeys[2] as keyof ResultValues] || 0,
            limit,
            edgeZoneLimit,
            getResultsValueText(keys[2]),
            isInEdgeZone
          )}
          {getResultTableRow(
            results[keys[3] as keyof ResultValues] || 0,
            edgeZoneResults[edgeZoneKeys[3] as keyof ResultValues] || 0,
            limit,
            edgeZoneLimit,
            getResultsValueText(keys[3]),
            isInEdgeZone
          )}
          {getResultTableRow(
            results[keys[4] as keyof ResultValues] || 0,
            edgeZoneResults[edgeZoneKeys[4] as keyof ResultValues] || 0,
            limit,
            edgeZoneLimit,
            getResultsValueText(keys[4]),
            isInEdgeZone
          )}
          {getResultTableRow(
            results[keys[5] as keyof ResultValues] || 0,
            edgeZoneResults[edgeZoneKeys[5] as keyof ResultValues] || 0,
            limit,
            edgeZoneLimit,
            getResultsValueText(keys[5]),
            isInEdgeZone
          )}
          {getResultTableRow(
            results[keys[6] as keyof ResultValues] || 0,
            edgeZoneResults[edgeZoneKeys[6] as keyof ResultValues] || 0,
            limit,
            edgeZoneLimit,
            getResultsValueText(keys[6]),
            isInEdgeZone
          )}
        </div>
        <hr className="mb-10 mt-8 bg-grayscale-25" />
      </div>
    ) : null
  }

  const resultListSystemLow = (
    results: ResultValuesLow,
    isInEdgeZone: boolean
  ) => {
    const limit = results.limit
    const edgeZoneLimit = limit
    return results !== undefined && results !== null ? (
      <div className="section">
        <div className="mb-8 table w-full table-fixed">
          <div className="table-row">
            <div className="table-cell py-1"></div>
            <div className="table-cell py-1 font-bold">{t('I randzon')}</div>
            <div className="table-cell py-1 font-bold">
              {t('Innanför randzon')}
            </div>
          </div>
          {getResultTableRow(
            results.mountCapacity.lift.insideEdgeZone,
            results.mountCapacity.lift.edgeZone,
            limit,
            edgeZoneLimit,
            'Max för lyft',
            isInEdgeZone
          )}
          {getResultTableRow(
            results.mountCapacity.pressure.insideEdgeZone,
            results.mountCapacity.pressure.edgeZone,
            limit,
            edgeZoneLimit,
            'Max för lyft',
            isInEdgeZone
          )}
        </div>
      </div>
    ) : null
  }

  const getResultsValueText = (key: string | undefined) => {
    switch (key) {
      case 'maxLift':
      case 'maxLiftWithSupportPlates':
      case 'railMountMaxLift':
      case 'railMountMaxLiftWithSupportPlates':
        return t('Max för lyft')
      case 'maxPressure':
      case 'railMountMaxPressure':
        return t('Max för tryck')
      case 'railConsolePartMax':
        return t('Konsolande del max')
      case 'railMountMaxHorizontal':
      case 'railMountMaxHorizontalWithSupportPlates':
        return t('Max för horisontell kraft')
      case 'railMountMaxLiftAndHorizontal':
      case 'railMountMaxLiftAndHorizontalWithSupportPlates':
        return t('Max för komb. lyft + h-last')
      case 'railMountMaxPressureAndHorizontal':
        return t('Max för komb. tryck + H-last')
      default:
        return 'missing result text'
    }
  }

  return panelAreaSections.map((panelAreaSection, index) => (
    <div key={index}>
      {panelAreaSection?.key === 1 &&
      system === 'low' &&
      'debugValues' in panelAreaSection.result.values ? (
        <>
          <Button
            variant="ghost"
            className="mb-10"
            onClick={() => setOpenDebugModal(panelAreaSection.panelAreaKey)}
          >
            {`${t('Visa debug-data för panelyta')} ${
              panelAreaSection.panelAreaKey
            }`}
          </Button>
          {openDebugModal === panelAreaSection.panelAreaKey ? (
            <DebugModal
              panelArea={panelAreas.find(
                (panelArea) => panelArea.uid === panelAreaSection.panelAreaUid
              )}
              panelAreaSections={panelAreaSections.filter(
                (filterPanelAreaSections) =>
                  filterPanelAreaSections.panelAreaKey ===
                  panelAreaSection.panelAreaKey
              )}
              closeModal={() => setOpenDebugModal(null)}
            />
          ) : null}
        </>
      ) : null}
      <div className="mb-6 flex items-center">
        {!panelAreaSection.result.isApproved ? (
          <FontAwesomeIcon
            className="mr-6 text-[32px] text-red-100"
            icon={faTriangleExclamation}
          />
        ) : null}
        <h2 className="text-xl">
          {t('Utnyttjandegrader')} – {t('Sektion')}{' '}
          {`${getSectionLabelText(
            panelAreaSection.uid,
            panelAreaSection.panelAreaUid,
            panelAreas,
            panelAreaSections
          )}`}
        </h2>
      </div>
      {system === 'parallel'
        ? results(panelAreaSection)
        : resultsLow(panelAreaSection)}
    </div>
  ))
}

export default UtilizationRates
