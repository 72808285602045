import { scaleMmToPixels, scalePixelsToMm } from '../utils/configurator'
import pointInPolygon from 'point-in-polygon'
import Konva from 'konva'
import { v4 as uuidv4 } from 'uuid'
import { round } from './utils'

/**
 * Creates panel area object
 *
 * @param position {Position}
 * @param configuratorPanelInfo {ConfiguratorPanelInfo}
 * @param mounting
 * @returns {PanelArea}
 */
export const createPanelArea = (
  position: Position,
  panelInfo: PanelInfo | PanelInfoLow,
  roofUid: string,
  system?: string
): PanelArea => {
  const uid = uuidv4()

  return {
    uid: uid,
    system: system || 'parallel',
    roofUid,
    useSupportPlates: panelInfo.useSupportPlates || false,
    size: {
      width: 0,
      height: 0
    },
    rows: 0,
    columns: 0,
    position: scalePixelsToMm<Position>(position),
    panelInfo,
    railAngle: getRailAngle(panelInfo),
    railsPerRow: panelInfo.useThreeRails ? 3 : 2,
    panels: [],
    removedPanels: []
  }
}

/**
 * Get rail angle for panel area
 */
export const getRailAngle = (panelInfo: PanelInfo | PanelInfoLow): number => {
  /** If low sloping */
  if ('system' in panelInfo && panelInfo.system === 'east/west') {
    return 90
  } else if ('system' in panelInfo && panelInfo.system === 'south') {
    return 0
  }

  /** If parallel */
  const railAngle = parseInt(panelInfo.mounting.split('-')[0])
  return !isNaN(railAngle) ? railAngle : 0
}

/**
 * Calculates the coordinates of a panel area based on the panel position, panel area position, and panel size.
 * @param panelPosition - The position of the panel.
 * @param panelAreaPosition - The position of the panel area relative to the panel.
 * @param panelSize - The size of the panel.
 * @returns An array of coordinates representing the panel area.
 */
export const getPanelCoordinates = (
  panelPosition: Position,
  panelAreaPosition: Position,
  panelSize: Size
) => {
  return [
    [
      panelPosition.x + panelAreaPosition.x,
      panelPosition.y + panelAreaPosition.y
    ],
    [
      panelPosition.x + panelAreaPosition.x + panelSize.width,
      panelPosition.y + panelAreaPosition.y
    ],
    [
      panelPosition.x + panelAreaPosition.x + panelSize.width,
      panelPosition.y + panelAreaPosition.y + panelSize.height
    ],
    [
      panelPosition.x + panelAreaPosition.x,
      panelPosition.y + panelAreaPosition.y + panelSize.height
    ]
  ]
}

/**
 * Calculates the coordinates of the panel area based on the position and size.
 * @param position The position of the panel area.
 * @param size The size of the panel area.
 * @returns An array of coordinates representing the panel area.
 */
export const getPanelAreaCoordinates = (position: Position, size: Size) => {
  return [
    [position.x, position.y],
    [position.x + size.width, position.y],
    [position.x + size.width, position.y + size.height],
    [position.x, position.y + size.height]
  ]
}

/**
 * Calculates the position of a panel area relative to roof.
 * @param stage - The Konva.Stage object representing the stage.
 * @param uid - The unique identifier of the panel area.
 * @param anchor - The anchor point of the panel area. Defaults to 'center'.
 * @param trimSize - The size of the trim around the panel area. Defaults to { width: 0, height: 0 }.
 * @returns The position of the panel area relative to  roof.
 */
export const getPanelAreaPosition = (
  stage: Konva.Stage,
  uid: string,
  anchor?: string,
  trimSize?: Size
): Position => {
  const panelAreaAbsolutePosition = stage
    ?.findOne(`#${uid}`)
    ?.absolutePosition() || { x: 1, y: 1 }
  const roofPosition = stage?.findOne('.roof')?.absolutePosition() || {
    x: 0,
    y: 0
  }
  if (!roofPosition || !panelAreaAbsolutePosition) {
    console.log('error getting position')
    return { x: 0, y: 0 }
  }
  const scaleX = stage?.findOne('.Main')?.attrs.scaleX || 1
  const scaleY = stage?.findOne('.Main')?.attrs.scaleY || 1
  let position = { x: 0, y: 0 }
  switch (anchor) {
    case 'top-left':
      position = {
        x:
          (panelAreaAbsolutePosition.x -
            roofPosition.x -
            (trimSize?.width || 0) * scaleX) /
          scaleX,
        y:
          (panelAreaAbsolutePosition.y -
            roofPosition.y -
            (trimSize?.height || 0) * scaleY) /
          scaleY
      }
      break
    case 'top-right':
      position = {
        x: (panelAreaAbsolutePosition.x - roofPosition.x) / scaleX,
        y:
          (panelAreaAbsolutePosition.y -
            roofPosition.y -
            (trimSize?.height || 0) * scaleY) /
          scaleY
      }
      break
    case 'bottom-left':
      position = {
        x:
          (panelAreaAbsolutePosition.x -
            roofPosition.x -
            (trimSize?.width || 0) * scaleX) /
          scaleX,
        y: (panelAreaAbsolutePosition.y - roofPosition.y) / scaleY
      }
      break
    default:
      position = {
        x: (panelAreaAbsolutePosition.x - roofPosition.x) / scaleX,
        y: (panelAreaAbsolutePosition.y - roofPosition.y) / scaleY
      }
      break
  }
  return scalePixelsToMm<Position>(position)
}

/**
 * Checks if a solar panel is placed on the roof based on its position, size, roof coordinates, and panel area position.
 * @param position The position of the solar panel.
 * @param panelSize The size of the solar panel.
 * @param roofCoordinates The coordinates of the roof.
 * @param panelAreaPosition The position of the panel area.
 * @returns A boolean indicating whether the solar panel is on the roof or not.
 */
const isPanelOnRoof = (
  position: Position,
  panelSize: Size,
  roofCoordinates: number[][],
  panelAreaPosition: Position
): boolean => {
  const panelCoords = getPanelCoordinates(
    position,
    panelAreaPosition,
    panelSize
  )
  for (const panelCoord of panelCoords) {
    if (roofCoordinates && !pointInPolygon(panelCoord, roofCoordinates)) {
      return false
    }
  }
  return true
}

/**
 * Returns max number of panels in x and y direction.
 *
 * @param {Konva.Rect} panelArea
 * @param {PanelInfo} panelInfo
 * @returns {{x: number, y: number}} number of panels in x and y direction
 */
export const getMaxPanels = (
  panelArea: Konva.Rect,
  panelInfo: PanelInfo | PanelInfoLow
): { x: number; y: number } => {
  const system = getSystemFromPanelInfo(panelInfo)
  switch (system) {
    case 'parallel':
      return getParallelMaxPanels(panelArea, panelInfo)
    case 'east/west':
      return getEastWestMaxPanels(panelArea, panelInfo as PanelInfoLow)
    case 'south':
      return getSouthMaxPanels(panelArea, panelInfo as PanelInfoLow)
    default:
      console.log('Invalid system: ' + system)
      return { x: 0, y: 0 }
  }
}

/**
 * Returns max number of panels in x and y direction.
 *
 * @param {Konva.Rect} panelArea
 * @param {PanelInfo} panelInfo
 * @returns {x: number of panels, y: number of panels}
 */
export const getMaxPanelsX = (
  panelArea: Konva.Rect,
  panelInfo: PanelInfo
): number => {
  const panelAreaWidth = Math.abs(panelArea.width() * panelArea.scaleX()) * 10
  const maxPanelsX = Math.ceil(
    round(
      Math.abs(panelAreaWidth + panelInfo.gap) /
        (panelInfo.widthMounted + panelInfo.gap),
      2
    )
  )
  return maxPanelsX
}

/**
 * Returns max number of panels in x direction.
 *
 * @param {Konva.Rect} panelArea
 * @param {PanelInfo} panelInfo
 * @returns {number} Number of panels in x direction
 */
export const getEastWestMaxPanelsX = (
  panelArea: Konva.Rect,
  panelInfo: PanelInfoLow
): number => {
  const panelAreaWidth = Math.abs(panelArea.width() * panelArea.scaleX()) * 10
  let panelsWidth = 0
  let numberOfPanels = 1
  let prevGap = 0
  for (let i = 0; panelsWidth < panelAreaWidth; i++) {
    const gap =
      i % 2 === 0 && i !== 0 && panelInfo.gapRow !== undefined
        ? panelInfo.gapRow
        : panelInfo.gapTop
    panelsWidth += panelInfo.widthMounted + prevGap
    panelsWidth = Math.ceil(panelsWidth / 10) * 10
    numberOfPanels = i + 1
    prevGap = gap
  }
  return numberOfPanels % 2 === 0 ? numberOfPanels : numberOfPanels + 1
}

/**
 * Returns max number of panels in y direction.
 *
 * @param {Konva.Rect} panelArea
 * @param {PanelInfo} panelInfo
 * @returns {number} Number of panels in x direction
 */
export const getSouthMaxPanelsY = (
  panelArea: Konva.Rect,
  panelInfo: PanelInfoLow
): number => {
  const panelAreaHeight = Math.abs(panelArea.height() * panelArea.scaleY()) * 10
  const maxPanelsY = Math.ceil(
    round(
      Math.abs(panelAreaHeight + panelInfo.gapRow) /
        (panelInfo.heightMounted + panelInfo.gapRow),
      2
    )
  )
  return maxPanelsY
}

/**
 * Returns max number of panels in y direction.
 *
 * @param {Konva.Rect} panelArea
 * @param {PanelInfo} panelInfo
 * @returns Number of panels in y direction
 */
export const getMaxPanelsY = (
  panelArea: Konva.Rect,
  panelInfo: PanelInfo
): number => {
  const panelAreaHeight = Math.abs(panelArea.height() * panelArea.scaleY()) * 10
  const maxPanelsY = Math.ceil(
    round(
      Math.abs(panelAreaHeight + panelInfo.gap) /
        (panelInfo.heightMounted + panelInfo.gap),
      2
    )
  )
  return maxPanelsY
}

/**
 * Returns max number of panels in x and y direction.
 *
 * @param {Konva.Rect} panelArea
 * @param {PanelInfo} panelInfo
 * @returns {x: number of panels, y: number of panels}
 */
export const getParallelMaxPanels = (
  panelArea: Konva.Rect,
  panelInfo: PanelInfo
): { x: number; y: number } => {
  return {
    x: getMaxPanelsX(panelArea, panelInfo),
    y: getMaxPanelsY(panelArea, panelInfo)
  }
}

/**
 * Returns max number of panels in x and y direction.
 *
 * @param {Konva.Rect} panelArea
 * @param {PanelInfo} panelInfo
 * @returns {x: number of panels, y: number of panels}
 */
export const getEastWestMaxPanels = (
  panelArea: Konva.Rect,
  panelInfo: PanelInfoLow
): { x: number; y: number } => {
  return {
    x: getEastWestMaxPanelsX(panelArea, panelInfo),
    y: getMaxPanelsY(panelArea, panelInfo)
  }
}

/**
 * Returns max number of panels in x and y direction.
 *
 * @param {Konva.Rect} panelArea
 * @param {PanelInfo} panelInfo
 * @returns {x: number of panels, y: number of panels}
 */
export const getSouthMaxPanels = (
  panelArea: Konva.Rect,
  panelInfo: PanelInfoLow
): { x: number; y: number } => {
  return {
    x: getMaxPanelsX(panelArea, panelInfo),
    y: getSouthMaxPanelsY(panelArea, panelInfo)
  }
}

/**
 * Calculates the X-coordinate for drawing a panel based on the panel information,
 * column index, and column offset.
 *
 * @param panelInfo - The panel information.
 * @param column - The column index.
 * @param columnOffset - The column offset.
 * @returns The X-coordinate for drawing the panel.
 */
const getDrawPanelX = (
  panelInfo: PanelInfo | PanelInfoLow,
  column: number,
  columnOffset: number
) => {
  const system = getSystemFromPanelInfo(panelInfo)
  if (system === 'east/west') {
    const panelInfoLow = panelInfo as PanelInfoLow
    const gapRowOffset = column % 2 !== 0 ? 1 : 0
    return (
      panelInfoLow.widthMounted * (column + columnOffset) +
      panelInfoLow.gapTop * Math.ceil(column / 2) +
      panelInfoLow.gapRow * Math.max(Math.ceil(column / 2 - gapRowOffset), 0)
    )
  }
  return (panelInfo.widthMounted + panelInfo.gap) * (column + columnOffset)
}

/**
 * Calculates the Y-coordinate for drawing a panel based on the panel information,
 * row number, and row offset.
 *
 * @param panelInfo - The panel information.
 * @param row - The row number.
 * @param rowOffset - The row offset.
 * @returns The Y-coordinate for drawing the panel.
 */
const getDrawPanelY = (
  panelInfo: PanelInfo | PanelInfoLow,
  row: number,
  rowOffset: number
) => {
  const system = getSystemFromPanelInfo(panelInfo)
  if (system === 'south') {
    const panelInfoLow = panelInfo as PanelInfoLow
    return (
      (panelInfoLow.heightMounted + panelInfoLow.gapRow) * (row + rowOffset)
    )
  }
  return (panelInfo.heightMounted + panelInfo.gap) * (row + rowOffset)
}

/**
 * Determines if a panel is located at the edge of the panel area.
 *
 * @param column - The column index of the panel.
 * @param row - The row index of the panel.
 * @param maxPanels - The maximum number of panels in the panel area.
 * @param system - The system type.
 * @returns A boolean indicating if the panel is at the edge.
 */
const getIsEdgePanel = (
  column: number,
  row: number,
  maxPanels: Position,
  system: string
) => {
  if (system === 'east/west') {
    return (
      column === maxPanels.x - 1 ||
      (column === maxPanels.x - 2 && maxPanels.x % 2 === 0) ||
      row === maxPanels.y - 1
    )
  }
  return column === maxPanels.x - 1 || row === maxPanels.y - 1
}

/**
 * Returns the fill color for a panel based on the trim styling and whether it is an edge panel.
 * @param trimStyling - A boolean indicating whether the panel has trim styling.
 * @param isEdgePanel - A boolean indicating whether the panel is an edge panel.
 * @returns The fill color for the panel.
 */
const getPanelFillColor = (
  onRoof: boolean,
  trimStyling: boolean,
  isEdgePanel: boolean
) => {
  if (onRoof) {
    return trimStyling && isEdgePanel ? '#f2f2f2' : '#808080'
  }
  return trimStyling && isEdgePanel ? '#F6C2C4' : '#EC848A'
}

/**
 * Draws panels within a panel area.
 *
 * @param panelArea - The Konva.Rect representing the panel area.
 * @param panelInfo - The information about the panel.
 * @param panelAreaUid - The unique identifier for the panel area.
 * @param removedPanels - An array of removed panels.
 * @param roofCoordinates - The coordinates of the roof.
 * @param trimStyling - Indicates whether trim styling is enabled or not. Default is false.
 * @returns An array of panels.
 */
export const drawPanels = (
  panelArea: Konva.Rect,
  panelInfo: PanelInfo | PanelInfoLow,
  panelAreaUid: string,
  removedPanels: { row: number; column: number }[],
  roofCoordinates: number[][],
  trimStyling = false
) => {
  const stage = panelArea.findAncestor('#stage') as Konva.Stage | null
  const panelAreaPosition = stage
    ? getPanelAreaPosition(stage, panelAreaUid)
    : panelArea.position()
  const panels = []
  const maxPanels = getMaxPanels(panelArea, panelInfo)
  const system = getSystemFromPanelInfo(panelInfo)
  for (let row = 0; row < maxPanels.y; row++) {
    for (let column = 0; column < maxPanels.x; column++) {
      const columnOffset = panelArea.width() < 0 ? 1 : 0
      const rowOffset = panelArea.height() < 0 ? 1 : 0
      let panelX = getDrawPanelX(panelInfo, column, columnOffset)
      let panelY = getDrawPanelY(panelInfo, row, rowOffset)
      if (panelArea.width() < 0) panelX = panelX * -1
      if (panelArea.height() < 0) panelY = panelY * -1
      const isEdgePanel = getIsEdgePanel(column, row, maxPanels, system)
      const onRoof = isPanelOnRoof(
        { x: panelX, y: panelY },
        { width: panelInfo.width, height: panelInfo.height },
        roofCoordinates,
        panelAreaPosition
      )
      panels.push({
        uid: uuidv4(),
        panelAreaUid: panelAreaUid,
        position: { x: panelX, y: panelY },
        column: column,
        row: row,
        size: {
          width: panelInfo.widthMounted,
          height: panelInfo.heightMounted
        },
        panelAreaSize: panelArea.size(),
        isEdgePanel,
        fill: getPanelFillColor(onRoof, trimStyling, isEdgePanel),
        removed: removedPanels.some(
          (removedPanel) =>
            removedPanel.row === row && removedPanel.column === column
        )
      })
    }
  }
  return panels
}

/**
 * Calculates and returns the size of the max amount of panels that fit inside the
 * drawn panel area based on panel size. Used when area drawing of panel area is
 * finished to snap the panel area to the correct dimensions.
 * @param {Konva.Rect} panelArea
 * @param {PanelInfo} panelInfo
 * @returns {number} Width of the panel area
 */
export const trimPanelsX = (
  panelArea: Konva.Rect,
  panelInfo: PanelInfo
): number => {
  const maxPanels = getMaxPanels(panelArea, panelInfo)
  const panelsWidth =
    round(
      (maxPanels.x * (panelInfo.widthMounted + panelInfo.gap) - panelInfo.gap) /
        10,
      0
    ) * 10
  const scaledPanelAreaSize = scalePixelsToMm<Size>(panelArea.size())
  if (panelsWidth === scaledPanelAreaSize.width) {
    return panelArea.size().width
  }
  const totalWidthPanels =
    panelInfo.widthMounted * (maxPanels.x - 1) +
    panelInfo.gap * (maxPanels.x - 2)
  const maxWidth =
    totalWidthPanels >= panelInfo.widthMounted
      ? totalWidthPanels
      : panelInfo.widthMounted
  return scaleMmToPixels(maxWidth / panelArea.scaleX())
}

/**
 * Calculates and returns the size of the max amount of panels that fit inside the
 * drawn panel area based on panel size. Used when area drawing of panel area is
 * finished to snap the panel area to the correct dimensions.
 * @param {Konva.Rect} panelArea
 * @param {PanelInfo} panelInfo
 * @returns {number} Height of the panel area
 */
export const trimPanelsY = (
  panelArea: Konva.Rect,
  panelInfo: PanelInfo
): number => {
  const maxPanels = getMaxPanels(panelArea, panelInfo)
  const panelsHeight =
    round(
      (maxPanels.y * (panelInfo.heightMounted + panelInfo.gap) -
        panelInfo.gap) /
        10,
      0
    ) * 10
  const scaledPanelAreaSize = scalePixelsToMm<Size>(panelArea.size())
  if (panelsHeight === scaledPanelAreaSize.height) {
    return panelArea.size().height
  }
  const totalPanelsHeight =
    panelInfo.heightMounted * (maxPanels.y - 1) +
    panelInfo.gap * (maxPanels.y - 2)
  const maxHeight =
    totalPanelsHeight >= panelInfo.heightMounted
      ? totalPanelsHeight
      : panelInfo.heightMounted
  return scaleMmToPixels(maxHeight / panelArea.scaleY())
}

/**
 * Calculates and returns the size of the max amount of panels that fit inside the
 * drawn panel area based on panel size. Used when area drawing of panel area is
 * finished to snap the panel area to the correct dimensions.
 * @param {Konva.Rect} panelArea
 * @param {PanelInfo} panelInfo
 * @returns {number} Height of the panel area
 */
export const trimSouthPanelsY = (
  panelArea: Konva.Rect,
  panelInfo: PanelInfoLow
): number => {
  const maxPanels = getMaxPanels(panelArea, panelInfo)
  const panelsHeight =
    round(
      (maxPanels.y * (panelInfo.heightMounted + panelInfo.gapRow) -
        panelInfo.gapRow) /
        10,
      0
    ) * 10
  const scaledPanelAreaSize = scalePixelsToMm<Size>(panelArea.size())
  if (panelsHeight === scaledPanelAreaSize.height) {
    return panelArea.size().height
  }
  const totalPanelsHeight =
    panelInfo.heightMounted * (maxPanels.y - 1) +
    panelInfo.gapRow * (maxPanels.y - 2)
  const maxHeight =
    totalPanelsHeight >= panelInfo.heightMounted
      ? totalPanelsHeight
      : panelInfo.heightMounted
  return scaleMmToPixels(maxHeight / panelArea.scaleY())
}

/**
 * Calculates and returns the size of the max amount of panels that fit inside the
 * drawn panel area based on panel size. Used when area drawing of panel area is
 * finished to snap the panel area to the correct dimensions.
 * @param {Konva.Rect} panelArea
 * @param {PanelInfo} panelInfo
 * @returns {Size} Size of the panel area
 */
export const trimParallelPanels = (
  panelArea: Konva.Rect,
  panelInfo: PanelInfo
): Size => {
  return {
    width: trimPanelsX(panelArea, panelInfo),
    height: trimPanelsY(panelArea, panelInfo)
  }
}

/**
 * Calculates and returns the size of the max amount of panels that fit inside the
 * drawn panel area based on panel size. Used when area drawing of panel area is
 * finished to snap the panel area to the correct dimensions.
 * @param {Konva.Rect} panelArea
 * @param {PanelInfo} panelInfo
 * @returns {number} Width of the panel area
 */
export const trimEastWestPanelsX = (
  panelArea: Konva.Rect,
  panelInfo: PanelInfoLow
): number => {
  let maxPanels = getEastWestMaxPanelsX(panelArea, panelInfo)
  let panelsWidth = 0
  let prevGap = 0

  for (let i = 0; i < maxPanels; i++) {
    const gap =
      i % 2 === 0 || i === 0
        ? panelInfo.gapTop
        : panelInfo.gapRow ?? panelInfo.gapTop
    panelsWidth += panelInfo.widthMounted + prevGap
    prevGap = gap
  }

  if (panelsWidth > scalePixelsToMm<number>(panelArea.size().width)) {
    let newPanelsWidth = 0
    prevGap = 0
    for (let i = 0; i < maxPanels - 2; i++) {
      const gap =
        i % 2 === 0 ? panelInfo.gapTop : panelInfo.gapRow ?? panelInfo.gapTop
      newPanelsWidth += panelInfo.widthMounted + prevGap
      prevGap = gap
    }
    panelsWidth = Math.ceil(newPanelsWidth / 10) * 10
  }
  return scaleMmToPixels(panelsWidth)
}

/**
 * Calculates and returns the size of the max amount of panels that fit inside the
 * drawn panel area based on panel size. Used when area drawing of panel area is
 * finished to snap the panel area to the correct dimensions.
 * @param {number} PanelArea
 * @param {PanelInfo} panelInfo
 * @returns {Size} Size of the panel area
 */
export const trimEastWestPanels = (
  panelArea: Konva.Rect,
  panelInfo: PanelInfoLow
): Size => {
  return {
    width: trimEastWestPanelsX(panelArea, panelInfo),
    height: trimPanelsY(panelArea, panelInfo)
  }
}

/**
 * Calculates and returns the size of the max amount of panels that fit inside the
 * drawn panel area based on panel size. Used when area drawing of panel area is
 * finished to snap the panel area to the correct dimensions.
 * @param {number} PanelArea
 * @param {PanelInfo} panelInfo
 * @returns {Size} Size of the panel area
 */
export const trimSouthPanels = (
  panelArea: Konva.Rect,
  panelInfo: PanelInfoLow
): Size => {
  return {
    width: trimPanelsX(panelArea, panelInfo),
    height: trimSouthPanelsY(panelArea, panelInfo)
  }
}

/**
 * Trims the panels based on the system type and panel information.
 *
 * @param panelArea The current panel area as a Konva.Rect object.
 * @param panelInfo The panel information object.
 * @returns The trimmed width and height of the panel area.
 */
export const trimPanels = (
  panelArea: Konva.Rect,
  panelInfo: PanelInfo | PanelInfoLow
) => {
  const system = getSystemFromPanelInfo(panelInfo)
  switch (system) {
    case 'parallel':
      return trimParallelPanels(panelArea, panelInfo)
    case 'east/west':
      return trimEastWestPanels(panelArea, panelInfo as PanelInfoLow)
    case 'south':
      return trimSouthPanels(panelArea, panelInfo as PanelInfoLow)
    default:
      console.log('Invalid system: ' + system)
      return {
        width: panelArea.width(),
        height: panelArea.height()
      }
  }
}

/**
 * Calculates the size of the panels based on the current panel area and panel information.
 * @param currentPanelArea The current panel area as a Konva.Rect object.
 * @param panelInfo The panel information object containing width, height, and gap values.
 * @returns The size of the panels in pixels.
 */
export const getPanelsSize = (panelArea: Konva.Rect, panelInfo: PanelInfo) => {
  const maxPanels = getMaxPanels(panelArea, panelInfo)
  const width =
    maxPanels.x * (panelInfo.widthMounted + panelInfo.gap) - panelInfo.gap
  const height =
    maxPanels.y * (panelInfo.heightMounted + panelInfo.gap) - panelInfo.gap
  return scaleMmToPixels<Size>({ width, height })
}

/**
 * Matches panel next to panel being hovered used to handle panels in pairs for low east/west system.
 * @param hoverPanel - The row column of the hovered panel.
 * @param currentPanel - The row column of the current panel.
 * @returns True if the current panel is next to the hovered panel, false otherwise.
 */
export const matchHoverPanelEastWest = (
  hoverPanel: { row: number; column: number },
  currentPanel: { row: number; column: number }
) => {
  const hoverPanelMatchColumn =
    hoverPanel.column % 2 === 0 ? hoverPanel.column + 1 : hoverPanel.column - 1
  if (
    hoverPanelMatchColumn === currentPanel.column &&
    hoverPanel.row === currentPanel.row
  ) {
    return true
  }
  return false
}

/**
 * Finds a panel in the given array of panels based on the target row and column.
 *
 * @param {Array} panels - The array of panels to search in.
 * @param {number} targetRow - The target row of the panel.
 * @param {number} targetColumn - The target column of the panel.
 * @returns {Panel | undefined} - The panel that matches the target row and column, or undefined if not found.
 */
export const findPanelByRowColumn = (
  panels: Panel[],
  targetRow: number,
  targetColumn: number
): Panel | undefined => {
  return panels.find(
    (panel) => panel.row === targetRow && panel.column === targetColumn
  )
}

/**
 * Returns the nearby panel column based on the given panel's row and column.
 * If the panel's row is even, the nearby panel column is the current column plus one.
 * If the panel's row is odd, the nearby panel column is the current column minus one.
 * @param {Panel} panel - The panel object containing the row and column information.
 * @returns {{row: number, column: number}}  The nearby panel object with updated column information.
 */
export const getNearbyPanelColumn = (
  panel: Panel
): { row: number; column: number } => {
  const nearbyPanel = {
    row: panel.row,
    column: panel.column
  }
  if (panel.row % 2 === 0) {
    nearbyPanel.column = panel.column + 1
  } else {
    nearbyPanel.column = panel.column - 1
  }
  return nearbyPanel
}

/**
 * Retrieves the system information from the panel information.
 * If the panel information contains a 'system' property, it returns the value of that property.
 * Otherwise, it returns the default value 'parallel'.
 *
 * @param {PanelInfo | PanelInfoLow} panelInfo - The panel information object.
 * @returns {string} The system information from the panel information.
 */
const getSystemFromPanelInfo = (panelInfo: PanelInfo | PanelInfoLow) =>
  'system' in panelInfo ? panelInfo.system : 'parallel'

/**
 * Returns an array of panel positions that have been marked as removed.
 * @param {Panel[]}panels - The array of panels to filter.
 * @returns {{row: number, column: number}[]} An array of panel positions with the `row` and `column` properties.
 */
const getRemovedPanels = (panels: Panel[]) =>
  panels
    .filter((panel) => panel.removed)
    .map((panel) => ({ row: panel.row, column: panel.column }))

/**
 * Converts an array of `PanelArea` objects into a format suitable for API.
 * @param {PanelArea[]} panelAreas - The array of `PanelArea` objects to be converted.
 * @returns {PanelArea[]} An array of `PanelArea` objects with modified properties for API.
 */
export const getPanelAreasForAPI = (panelAreas: PanelArea[]) => {
  const clonedPanelAreas = structuredClone(panelAreas)
  return clonedPanelAreas.map((panelArea) => {
    panelArea.removedPanels = getRemovedPanels(panelArea.panels)
    panelArea.panels = []
    return panelArea
  })
}

/**
 * Calculates the transformed size based on the anchor and pixel size.
 * @param anchor - The anchor position ('top-left', 'top-right', 'bottom-left').
 * @param transformSize - The current size of the transformation.
 * @param pixelSize - The size of the pixel.
 * @returns The updated transformed size.
 */
export const getTransformSize = (
  anchor: string,
  // transformSize: Size,
  pixelSize: Size
): Size => {
  const transformSize = structuredClone(pixelSize)
  switch (anchor) {
    case 'top-left':
      transformSize.width = -transformSize.width
      transformSize.height = -transformSize.height
      break
    case 'top-right':
      transformSize.height = -transformSize.height
      break
    case 'bottom-left':
      transformSize.width = -transformSize.width
      break
  }
  return transformSize
}

/**
 * Calculates the transform position based on the anchor, pixel size, and pixel position.
 * @param anchor - The anchor position of the transform.
 * @param transformPosition - The current position of the transform.
 * @param pixelSize - The size of the pixel.
 * @param pixelPosition - The position of the pixel.
 * @returns The updated transform position.
 */
export const getTransformPosition = (
  anchor: string,
  pixelSize: Size,
  pixelPosition: Position
): Position => {
  const transformSize = structuredClone(pixelSize)
  const transformPosition = structuredClone(pixelPosition)
  switch (anchor) {
    case 'top-left':
      transformPosition.x = transformPosition.x + transformSize.width
      transformPosition.y = transformPosition.y + transformSize.height
      break
    case 'top-right':
      transformPosition.y = transformPosition.y + transformSize.height
      break
    case 'bottom-left':
      transformPosition.x = transformPosition.x + transformSize.width
      break
  }
  return transformPosition
}

/**
 * Calculates the transformed width based on the anchor and the original width.
 * @param anchor - The anchor ('top-left', 'bottom-left', etc.).
 * @param width - The original width.
 * @returns The transformed width.
 */
export const getTransformWidth = (anchor: string, width: number): number => {
  width = Math.round(width)
  if (anchor === 'top-left' || anchor === 'bottom-left') {
    return width * -1
  }
  return width
}

/**
 * Calculates the transformer height based on the anchor and the provided height.
 * @param anchor - The anchor of the transformer.
 * @param height - The height value to calculate the transformer height.
 * @returns The calculated transformer height.
 */
export const getTransformerHeight = (
  anchor: string,
  height: number
): number => {
  height = Math.round(height)
  if (anchor === 'top-left' || anchor === 'top-right') {
    return height * -1
  }
  return height
}

/**
 * Calculates the position of the horizontal measurement indicator for a given panel area.
 * @param currentPanelArea - The current panel area.
 * @returns The position of the horizontal measurement indicator.
 */
export const getHorizontalMeasurementIndicatorPosition = (
  currentPanelArea: Konva.Rect
): Position => {
  const x =
    currentPanelArea.x() +
    (currentPanelArea.width() * currentPanelArea.scaleX()) / 2
  const y =
    currentPanelArea.y() + currentPanelArea.height() * currentPanelArea.scaleY()
  return { x, y }
}

/**
 * Calculates the position of the vertical measurement indicator based on the current panel area.
 * @param currentPanelArea - The current panel area represented by a Konva.Rect object.
 * @returns The position of the vertical measurement indicator as an object with `x` and `y` coordinates.
 */
export const getVerticalMeasurementIndicatorPosition = (
  currentPanelArea: Konva.Rect
): Position => {
  const x =
    currentPanelArea.width() > 0
      ? currentPanelArea.x() * currentPanelArea.scaleX()
      : currentPanelArea.x() * currentPanelArea.scaleX() +
        currentPanelArea.width()
  const y =
    currentPanelArea.y() +
    (currentPanelArea.height() * currentPanelArea.scaleY()) / 2
  return { x, y }
}

/**
 * Calculates the position of the panel area actions.
 * @param currentPanelArea - The current panel area.
 * @returns The position of the panel area actions.
 */
export const getPanelAreaActionsPosition = (
  currentPanelArea: Konva.Rect
): Position => {
  const x =
    currentPanelArea.x() +
    (currentPanelArea.width() * currentPanelArea.scaleX()) / 2
  const y =
    currentPanelArea.height() > 0
      ? currentPanelArea.y() - 8
      : currentPanelArea.y() +
        (currentPanelArea.height() - 8) * currentPanelArea.scaleY()
  return { x, y }
}

/**
 * Calculates the label position for the panel area amount.
 *
 * @param currentPanelArea - The current panel area rectangle.
 * @returns The position of the label.
 */
export const getPanelAreaAmountLabelPosition = (
  currentPanelArea: Konva.Rect
): Position => {
  const x =
    currentPanelArea.width() > 0
      ? (currentPanelArea.x() + currentPanelArea.width()) *
        currentPanelArea.scaleX()
      : currentPanelArea.x() * currentPanelArea.scaleX()
  const y =
    currentPanelArea.y() +
    (currentPanelArea.height() * currentPanelArea.scaleY()) / 2
  return { x, y }
}

/**
 * Calculates the position for the remove panel area popup.
 * @param currentPanelArea - The current panel area.
 * @param scale - The scale factor.
 * @returns The position of the remove panel area popup.
 */
export const getRemovePanelAreaPopupPosition = (
  currentPanelArea: Konva.Rect,
  scale: number
): Position => {
  const x =
    currentPanelArea.x() +
    16 / scale +
    (currentPanelArea.width() * currentPanelArea.scaleX()) / 2

  const y =
    currentPanelArea.height() > 0
      ? currentPanelArea.y() - 32 / scale
      : currentPanelArea.y() +
        (currentPanelArea.height() - 32 / scale) * currentPanelArea.scaleY()

  return { x, y }
}
